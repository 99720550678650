






























































































// @ts-ignore
import { FormNamespaceRegistrationTransactionTs } from './FormNamespaceRegistrationTransactionTs';
export default class FormNamespaceRegistrationTransaction extends FormNamespaceRegistrationTransactionTs {}
