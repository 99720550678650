






























import { CreateSubNamespaceTs } from './CreateSubNamespaceTs';
export default class CreateSubNamespace extends CreateSubNamespaceTs {}
