

















import { AssetFormPageWrapTs } from './AssetFormPageWrapTs';
export default class AssetFormPageWrap extends AssetFormPageWrapTs {}
